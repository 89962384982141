<template>
  <!-- データ取引契約管理 -->
  <base-layout v-bind:title="title" v-bind:sub-title="subTitle">
    <!-- PLR基盤との同期 -->
    <template v-slot:pxr>
      <sync-pxr
        v-bind:is-processing="isPxrProcessing"
        v-bind:sync-date="pxrSyncDate"
        v-on:sync-click="syncPxrData()"
      />
    </template>
    <!-- 検索 -->
    <template v-slot:search>
      <search-form
        v-bind:states="states"
        v-bind:disabled="isPxrProcessing"
        v-on:search-click="getSearchResult"
      />
    </template>
    <!-- 検索結果一覧 -->
    <template v-slot:list>
      <base-table
        v-bind:fields="contractListFields"
        v-bind:items="contractList"
        v-bind:is-pagination="true"
        v-bind:is-selectable="true"
        v-bind:default-sort="defaultSort"
        v-on:row-selected="getDetail($event, (isUpdate = false))"
      />
      <p v-if="listMessage" class="text-center text-caccent">
        {{ listMessage }}
      </p>
      <p v-else-if="contractList.length == 0" class="text-center text-csub">
        検索した結果が表示されます
      </p>
    </template>
    <!-- 詳細 -->
    <template v-slot:detail v-if="contractSelected">
      <!-- 情報 -->
      <information v-bind:contract-info="contractInfo" />
      <!-- 帳票発行 -->
      <form-issuance
        v-bind:id="contractSelected.contractId"
        v-bind:items="issuedList"
        v-bind:fields="issuedListFields"
        v-bind:is-issuable="issuableStateId.includes(contractInfo.stateId)"
        v-on:form-isuued="getDetail(contractSelected, (isUpdate = true))"
      />
      <b-card no-body class="mb-5">
        <b-card-body>
          <b-card-title title-tag="h3">
            提供元ワークフロープロバイダー
          </b-card-title>
          <base-table v-bind:items="wfpList" v-bind:fields="wfpListFields" />
          <p v-if="wfpList.length == 0" class="text-center text-caccent">
            提供元ワークフロープロバイダーのデータがありません。
          </p>
        </b-card-body>
      </b-card>
      <b-card no-body class="mb-5">
        <b-card-body>
          <b-card-title title-tag="h3"> 対象グループ・募集状況 </b-card-title>
          <base-table
            v-bind:items="groupList"
            v-bind:fields="groupListFields"
          />
          <p v-if="groupList.length == 0" class="text-center text-caccent">
            対象グループのデータがありません。
          </p>
        </b-card-body>
      </b-card>
      <b-card no-body class="mb-5">
        <b-card-body>
          <b-card-title title-tag="h3"> 対象データ種・募集状況 </b-card-title>
          <div v-for="dataType in dataTypeList" v-bind:key="dataType.eventCode">
            <h4 v-if="dataType.eventCode">
              {{ dataType.eventCode }} {{ dataType.eventName }}
            </h4>
            <h4 v-else>イベントなし</h4>
            <base-table
              v-bind:items="dataType.thingList"
              v-bind:fields="dataTypeListFields"
            />
          </div>
        </b-card-body>
      </b-card>
    </template>
  </base-layout>
</template>

<script>
import BaseLayout from '@/components/common/BaseLayout'
import SearchForm from '@/components/data-contract/SearchForm'
import Information from '@/components/data-contract/Information'
import BaseTable from '@/components/common/BaseTable'
import FormIssuance from '@/components/data-contract/FormIssuanceCard'
import SyncPxr from '@/components/common/SyncPxrData'
import { convertTimezone, getPxrSyncDate } from '@/mixins/utility'
export default {
  components: {
    BaseLayout,
    SearchForm,
    Information,
    BaseTable,
    FormIssuance,
    SyncPxr,
  },
  mixins: [convertTimezone, getPxrSyncDate],
  data() {
    return {
      title: 'データ取引契約',
      subTitle: '（契約書・見積書・請求書発行）',
      pageId: 5,
      // ***** 検索リスト選択用 *****
      states: [],
      // ***** データ取引契約一覧 *****
      contractList: [],
      contractListFields: [
        { key: 'contractId', label: '契約ID', tdClass: 'text-center' },
        { key: 'proposalState', label: '契約ステータス' },
        { key: 'title', label: '契約名' },
        { key: 'actorCode', label: 'ActorCode' },
        { key: 'name', label: '名称' },
        { key: 'numOfGroups', label: 'グループ数', tdClass: 'text-right' },
        { key: 'numOfDataType', label: 'データ種数', tdClass: 'text-right' },
        { key: 'approvalAt', label: '承認日', tdClass: 'text-center' },
        {
          key: 'recruitmentAtStart',
          label: '募集開始',
          tdClass: 'text-center',
        },
        { key: 'recruitmentAtEnd', label: '募集終了', tdClass: 'text-center' },
        { key: 'fulfillmentAt', label: '履行日', tdClass: 'text-center' },
      ],
      defaultSort: { by: 'contractId', desc: true },
      contractSelected: null,
      listMessage: null,
      // ***** 選択データ取引契約詳細 *****
      // データ取引契約情報
      contractInfo: {
        contractId: null,
        proposalState: null,
        stateId: null,
        title: null,
        actorCode: null,
        name: null,
        numOfGroups: null,
        numOfDataType: null,
        numOfPeople: null,
        totalPrice: null,
      },
      // 提供元wfp
      wfpList: [],
      wfpListFields: [
        { key: 'actorCode', label: 'ActorCode', tdClass: 'text-center' },
        { key: 'name', label: '名称' },
        { key: 'state', label: '状況', tdClass: 'text-center' },
      ],
      // 対象グループ一覧
      groupList: [],
      groupListFields: [
        { key: 'groupId', label: 'グループ', tdClass: 'text-center' },
        { key: 'min', label: '募集最小人数', tdClass: 'text-right' },
        { key: 'max', label: '募集最大人数', tdClass: 'text-right' },
        { key: 'numOfPeople', label: '募集人数', tdClass: 'text-right' },
      ],
      // 対象データ種一覧
      dataTypeList: [],
      dataTypeListFields: [
        { key: 'thingCode', label: 'Code' },
        { key: 'thingName', label: 'データ種' },
        { key: 'rate', label: 'レート（コイン/件）', tdClass: 'text-right' },
        { key: 'min', label: '最小データ量（件）', tdClass: 'text-right' },
        { key: 'max', label: '最大データ量（件）', tdClass: 'text-right' },
        { key: 'minCost', label: '価格（最小）', tdClass: 'text-right' },
        { key: 'maxCost', label: '価格（最大）', tdClass: 'text-right' },
        {
          key: 'numOfData',
          label: '募集データ量（件）',
          tdClass: 'text-right',
          thClass: 'bg-caccent border-caccent',
        },
        {
          key: 'price',
          label: '提供の対価',
          tdClass: 'text-right',
          thClass: 'bg-caccent border-caccent',
        },
      ],
      // 帳票発行
      issuedList: [], // 発行済み一覧
      issuedListFields: [
        { key: 'issuedAt', label: '発行日', tdClass: 'text-center' },
        { key: 'type', label: '帳票', tdClass: 'text-center' },
        { key: 'code', label: '番号', tdClass: 'text-center' },
        { key: 'button', label: 'ダウンロード', class: 'text-center' },
      ],
      issuableStateId: [7, 8, 9], // 発行可能ステータス
      // PLR基盤との同期
      isPxrProcessing: false,
      pxrSyncDate: null,
    }
  },
  created() {
    // 最終同期日時取得
    this.getPxrSyncDate(this.pageId).then((ret) => {
      this.pxrSyncDate = this.convertTimezone(ret, false)
    })
    // 検索リスト項目取得
    this.getSearchSelectItems()
  },
  methods: {
    syncPxrData() {
      // PLR基盤とのデータ同期
      console.log('** pxr data sync **')
      this.isPxrProcessing = true
      // 表示されているものはクリア
      this.states = []
      this.contractList = []
      this.contractSelected = null

      this.$axiosToken
        .post('/api/update/all')
        .then((response) => {
          console.log(response)
          // 同期日時取得
          this.getPxrSyncDate(this.pageId).then(
            (ret) => (this.pxrSyncDate = this.convertTimezone(ret, false))
          )
        })
        .catch((error) => {
          console.log(error)
          if (error.response) {
            console.log(error.response)
            alert(error.response.data.detail)
          } else {
            alert('PLR基盤からのデータ取得に失敗しました。')
          }
        })
        .finally(() => {
          this.isPxrProcessing = false
          // 検索リスト選択項目取得
          this.getSearchSelectItems()
        })
    },
    getSearchSelectItems() {
      // 検索でリスト選択項目取得
      console.log('** get search select items **')
      this.$axiosToken
        .get('/contract/proposal_state')
        .then((response) => {
          console.log(response)
          this.states = response.data.stateList
          this.states.unshift({ id: null, state: '' })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getSearchResult(searchItems) {
      // データ取引契約一覧取得
      console.log('searchItems', searchItems)
      this.listMessage = null
      this.$axiosToken
        .get('/contract/list', {
          params: {
            state: searchItems.state,
            title: searchItems.title,
            actor_code: searchItems.actorCode,
            name: searchItems.name,
          },
        })
        .then((response) => {
          console.log('** contract list **', response)
          this.contractList = response.data
          if (this.contractList.length == 0) {
            this.listMessage = '該当するデータ取引契約がありません。'
          }
          for (let i = 0; i < this.contractList.length; i++) {
            this.contractList[i].approvalAt = this.convertTimezone(
              this.contractList[i].approvalAt
            )
            this.contractList[i].recruitmentAtStart = this.convertTimezone(
              this.contractList[i].recruitmentAtStart
            )
            this.contractList[i].recruitmentAtEnd = this.convertTimezone(
              this.contractList[i].recruitmentAtEnd
            )
            this.contractList[i].fulfillmentAt = this.convertTimezone(
              this.contractList[i].fulfillmentAt
            )
          }
        })
        .catch((error) => {
          console.log(error)
          this.contractList = []
          this.listMessage = 'データ取引契約の取得に失敗しました。'
        })
    },
    getDetail(selectedRow, isUpdate) {
      // 選択されたデータ取引契約の詳細取得 isUpdate true:更新 false:行選択で新たに取得
      console.log('** get detail **', selectedRow, isUpdate)
      if (!isUpdate && !selectedRow) {
        this.contractSelected = null
        console.log('*** 選択解除')
        return
      }
      // -- データ取引契約詳細get --
      this.$axiosToken
        .get('/contract/' + selectedRow['contractId'])
        .then((response) => {
          console.log('** get contract detail **', response)
          this.contractInfo.contractId = response.data.contractId //info
          this.contractInfo.proposalState = response.data.proposalState //info
          this.contractInfo.stateId = response.data.stateId //info
          this.contractInfo.title = response.data.title //info
          this.contractInfo.actorCode = response.data.actorCode //info
          this.contractInfo.name = response.data.name //info
          this.contractInfo.numOfGroups = response.data.numOfGroups //info
          this.contractInfo.numOfDataType = response.data.numOfDataType //info
          this.contractInfo.numOfPeople = response.data.numOfPeople //info
          this.contractInfo.totalPrice = response.data.totalPrice //info
          this.issuedList = response.data.docList //issued form
          // 日付項目をLocalTimeに変換
          for (let i = 0; i < this.issuedList.length; i++) {
            this.issuedList[i].issuedAt = this.convertTimezone(
              this.issuedList[i].issuedAt,
              false
            )
          }
          this.wfpList = response.data.wfProviders //wfp
          this.groupList = response.data.groupInfo //target group
          this.dataTypeList = response.data.dataTypeList //target data type
          if (!isUpdate) {
            this.contractSelected = selectedRow
          }
        })
        .catch((error) => {
          console.log(error)
          // 行選択でエラーの場合はクリア 更新の時は表示したままにする
          if (isUpdate) {
            alert('データ取引契約詳細の更新に失敗しました。')
          } else {
            this.contractSelected = null
            alert('データ取引契約詳細の取得に失敗しました。')
          }
        })
    },
  },
}
</script>
