<template>
  <!-- 帳票発行Card -->
  <b-card no-body class="mb-5">
    <b-card-body>
      <b-card-title title-tag="h3" class="d-none">
        契約書・見積書・請求書発行
      </b-card-title>
      <b-card-text class="text-csub">
        <b-icon icon="info-circle-fill"></b-icon>
        状態が「履行待ち」「収集済」「履行済」の場合に発行可能です。
      </b-card-text>
      <b-form-row>
        <b-col>
          <b-button
            variant="outline-csub"
            class="btn-issue"
            v-bind:disabled="!isIssuable"
            v-on:click="isuueForm(1)"
          >
            <b-icon icon="file-earmark-text"></b-icon> 契約書発行
          </b-button>
        </b-col>
        <b-col>
          <b-button
            variant="outline-csub"
            class="btn-issue"
            v-bind:disabled="!isIssuable"
            v-on:click="isuueForm(2)"
          >
            <b-icon icon="file-earmark-text"></b-icon> 見積書発行
          </b-button>
        </b-col>
        <b-col>
          <b-button
            variant="outline-csub"
            class="btn-issue"
            v-bind:disabled="!isIssuable"
            v-on:click="isuueForm(3)"
          >
            <b-icon icon="file-earmark-text"></b-icon> 請求書発行
          </b-button>
        </b-col>
      </b-form-row>
      <b-alert v-if="formPath" show variant="csub">
        <a v-bind:href="formPath" class="alert-link">
          {{ issuedForm }} の作成が完了しました。<b-icon
            icon="box-arrow-in-down"
            shift-v="2"
          ></b-icon
          >ダウンロード {{ formPath }}
        </a>
      </b-alert>
      <b-card-title title-tag="h4">発行済み一覧</b-card-title>
      <b-table
        v-bind:items="items"
        v-bind:fields="fields"
        responsive
        bordered
        head-variant="dark"
      >
        <template v-slot:cell(button)="{ item }">
          <b-button variant="csub" size="sm" v-bind:href="item.uri">
            <b-icon icon="box-arrow-in-down" shift-v="2"></b-icon> ダウンロード
          </b-button>
        </template>
      </b-table>
      <p v-if="items.length == 0" class="text-center text-caccent">
        発行済みの帳票はありません。
      </p>
    </b-card-body>
  </b-card>
</template>

<script>
import textGettable from '@/mixins/textGettable'
export default {
  mixins: [textGettable],
  props: {
    id: { default: null },
    items: { default: () => [] },
    fields: { default: () => [] },
    isIssuable: { type: Boolean, default: false },
  },
  data() {
    return {
      formPath: null,
      issuedForm: null,
    }
  },
  watch: {
    id(val) {
      console.log('** watch id **', val)
      this.formPath = null
    },
  },
  methods: {
    isuueForm(formType) {
      console.log('** isuueFormn **', formType)
      if (formType == 1) {
        alert('契約書発行機能は未作成です。（テンプレート待ち）')
        return
      }
      this.$axiosToken
        .post('/contract/issue_document', {
          id: this.id,
          form_type: formType,
        })
        .then((response) => {
          console.log(response)
          this.formPath = response.data.uri
          this.issuedForm = response.data.filename
          // 一覧更新のため親へ通知
          this.$emit('form-isuued')
        })
        .catch((error) => {
          console.log(error)
          if (error.response) {
            console.log(error.response)
            alert(error.response.data.detail)
          } else {
            alert('帳票の発行に失敗しました。')
          }
        })
    },
  },
}
</script>
<style scoped>
.btn-issue {
  line-height: 2.5rem;
  width: 100%;
  margin-bottom: 1rem;
}
</style>
