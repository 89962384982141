<template>
  <!--  データ取引契約情報表示 -->
  <b-card no-body class="mb-5">
    <b-card-body class="bg-light">
      <b-form-row class="mb-3">
        <b-col>
          <p class="lead">
            {{ contractInfo.contractId }} {{ contractInfo.title }}
            <span class="text-caccent">{{ contractInfo.proposalState }}</span>
          </p>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <base-input
            v-bind:value="contractInfo.actorCode + ' : ' + contractInfo.name"
            label="データ取引会員"
            id="select-consumer"
            v-bind:disabled="true"
          >
          </base-input>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col sm="3" lg="2">
          <base-input
            v-bind:value="contractInfo.numOfGroups"
            label="対象グループ数"
            id="select-group"
            text-class="text-right"
            v-bind:disabled="true"
          >
          </base-input>
        </b-col>
        <b-col sm="3" lg="2">
          <base-input
            v-bind:value="contractInfo.numOfDataType"
            label="対象データ種数"
            id="select-type"
            text-class="text-right"
            v-bind:disabled="true"
          >
          </base-input>
        </b-col>
        <b-col sm="3" lg="2">
          <base-input
            v-bind:value="contractInfo.numOfPeople"
            label="募集人数合計"
            id="select-total-consents"
            text-class="text-right"
            v-bind:disabled="true"
          >
          </base-input>
        </b-col>

        <b-col sm="3" lg="2">
          <base-input
            v-bind:value="contractInfo.totalPrice"
            label="提供の対価合計"
            id="select-total-price"
            text-class="text-right"
            v-bind:disabled="true"
          >
          </base-input>
        </b-col>
      </b-form-row>
    </b-card-body>
  </b-card>
</template>

<script>
import BaseInput from '@/components/common/BaseInput'
export default {
  props: ['contractInfo'],
  components: {
    BaseInput,
  },
}
</script>
