<template>
  <!--  データ契約検索フォーム -->
  <b-form v-on:submit.prevent="onSearchClick()">
    <b-form-row>
      <b-col md="4" lg="3">
        <base-select
          v-model="search.state"
          label="契約ステータス"
          id="search-state"
          value-field="id"
          text-field="state"
          v-bind:options="states"
        >
        </base-select>
      </b-col>
      <b-col md="8" lg="9">
        <base-input
          v-model="search.title"
          label="契約名*"
          id="search-title"
        >
        </base-input>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col md="4" lg="3">
        <base-input
          v-model="search.actorCode"
          label="データ取引会員ActorCode"
          id="search-actor-code"
          pattern="^[0-9]+$"
          title="数値のみ"
        >
        </base-input>
      </b-col>
      <b-col md="8" lg="9">
        <base-input
          v-model="search.name"
          label="データ取引会員名称*"
          id="search-name"
        >
        </base-input>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col offset-sm="4" sm="4" offset-lg="5" lg="2">
        <b-button
          type="submit"
          variant="csub"
          class="w-100"
          v-bind:disabled="disabled"
        >
          <b-icon icon="search"></b-icon>
          検索
        </b-button>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import BaseInput from '@/components/common/BaseInput'
import BaseSelect from '@/components/common/BaseSelect'
export default {
  components: {
    BaseInput,
    BaseSelect,
  },
  props: ['states', 'disabled'],
  data() {
    return {
      search: {
        state: null,
        title: null,
        actorCode: null,
        name: null,
      },
    }
  },
  methods: {
    onSearchClick() {
      this.$emit('search-click', this.search)
    },
  },
}
</script>
